//colors
$color_malibu_approx: #87cefa;
$white: #fff;
$color_cerulean_approx: #098ddf;
$color_shady_lady_approx: #a9a9a9;
$black: #000;
$mercury: #e5e5e5;
$color_celeste_approx: #ccc;
$color_log_cabin_approx: #222;
$color_bon_jour_approx: #e2e2e2;
$color_tapa_approx: #777;
$color_st_tropaz_approx: #286090;
$color_killarney_approx: #3c763d;
$color_calypso_approx: #31708f;
$color_pesto_approx: #8a6d3b;
$color_apple_blossom_approx: #a94442;
$color_wild_sand_approx: #f5f5f5;
$color_willow_brook_approx: #dff0d8;
$color_link_water_approx: #d9edf7;
$color_solitaire_approx: #fcf8e3;
$color_vanilla_ice_approx: #f2dede;
$color_alto_approx: #ddd;
$color_gallery_approx: #eee;
$color_quill_gray_approx: lightgray;
$gray: gray;
$color_boston_blue_approx: #428bca;